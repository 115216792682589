import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Macey Wong",
  "authorDescription": "hi",
  "date": "2013-10-31 UTC -8",
  "length": null,
  "location": "Macedonia",
  "path": "/sarplaninac-dogs/",
  "tags": ["dogs", "personal"],
  "title": "Sarplaninac Dogs: The Unknown Breed"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABcBAAMBAAAAAAAAAAAAAAAAAAECAwT/2gAMAwEAAhADEAAAAaVuny0yAx//xAAbEAACAwADAAAAAAAAAAAAAAABAgADERIiMv/aAAgBAQABBQIImeY47LYZe2x2PL//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECERL/2gAIAQIBAT8BjbMs/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhQf/aAAgBAQAGPwKha7GBIs//xAAbEAACAgMBAAAAAAAAAAAAAAAAARExIUFRcf/aAAgBAQABPyG/m/BxDVwzoXRMuMj25bG2hkj/2gAMAwEAAgADAAAAEI/f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxC2sKP/xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EGlpkH//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMXGB/9oACAEBAAE/ENhKK6ly661z1LkqmcY9AMdY0gFL9NSiEYE//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sarplaninac Dog",
          "title": "Sarplaninac Dog",
          "src": "/static/1cac920b8843c043854ad82948c38850/8e1fc/sarplaninac.jpg",
          "srcSet": ["/static/1cac920b8843c043854ad82948c38850/863e1/sarplaninac.jpg 225w", "/static/1cac920b8843c043854ad82948c38850/20e5d/sarplaninac.jpg 450w", "/static/1cac920b8843c043854ad82948c38850/8e1fc/sarplaninac.jpg 900w", "/static/1cac920b8843c043854ad82948c38850/b768e/sarplaninac.jpg 1350w", "/static/1cac920b8843c043854ad82948c38850/d9c39/sarplaninac.jpg 1800w", "/static/1cac920b8843c043854ad82948c38850/e1596/sarplaninac.jpg 2048w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The Šarplaninac or Yugoslavian Shepherd Dog (Serbian Cyrillic: Шарпланинац; formerly known as Ilirski Ovčar; Illyrian Shepherd dog) is a dog breed of the livestock guardian type named after the Šar Mountains. It is a molosser-type mountain dog.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The Sar holds allegiance to only one master; everyone else is the enemy.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44444444444444%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAP/aAAwDAQACEAMQAAABfOhjcUoC/wD/xAAcEAABAwUAAAAAAAAAAAAAAAACAAESAxEiMkH/2gAIAQEAAQUCe8QepNwzBdLf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAIRInEQEjH/2gAIAQEABj8Ci7ktuiryqz//xAAaEAEBAAIDAAAAAAAAAAAAAAABADFRIWGh/9oACAEBAAE/IWXmazEAkbF1vOwme5f/2gAMAwEAAgADAAAAEJwv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/ENWP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EMV//8QAHhABAAIBBAMAAAAAAAAAAAAAAQARITFBUXGBsfH/2gAIAQEAAT8QSkCATdLz6lWCDJSdxQEaYHuZ+BG7+I3V+0//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sarplaninac Dog Book",
          "title": "Sarplaninac Dog Book",
          "src": "/static/0ea4d947b46d270ee5fbe3b089daec5d/8e1fc/macey_book.jpg",
          "srcSet": ["/static/0ea4d947b46d270ee5fbe3b089daec5d/863e1/macey_book.jpg 225w", "/static/0ea4d947b46d270ee5fbe3b089daec5d/20e5d/macey_book.jpg 450w", "/static/0ea4d947b46d270ee5fbe3b089daec5d/8e1fc/macey_book.jpg 900w", "/static/0ea4d947b46d270ee5fbe3b089daec5d/6a068/macey_book.jpg 960w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      